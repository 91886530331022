import React, {Fragment} from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton
} from '@elastic/eui'

function TOCList() {

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Metro/Qrcode_Microsoft-AppStore.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
              }
              title="Windows Store Link"
              description="You can use a QR Reader to install our App. Or just click the image to be directed to the Microsoft Store."
              footer= {
                <Fragment>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem>
                            <EuiButton href="https://www.microsoft.com/de-de/store/p/instarvision/9nblggh10wtp" target="_blank" fill size="s">
                              Microsoft Store
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton href="https://www.microsoft.com/en-us/store/p/qr-code-reader/9wzdncrfj1s9" target="_blank" fill size="s">
                              QR Reader
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
    </Fragment>
  );
}

export default TOCList